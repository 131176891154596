<template>
  <referentiel-list
  :listTitle="'Liste des organismes'"
  :referentielName="referentielName"
  :tableFields="tableFields"
  :emptyReferentielItem="emptyReferentielItem"
  >
  <template #formContent="{ handleInput, editableItem }">
    <div class="row">
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.intitule" @input="handleInput" type="text" class="form-control" id="intitule" name="intitule" placeholder="Intitulé">
            <label for="name">Intitutlé</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.description" @input="handleInput" type="text" class="form-control" id="description" name="description" placeholder="Description">
            <label for="description">Description</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12">
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select id="parent" name="parent" option-label="intitule" option-value="id" v-model="editableItem.parent" :options="nullableOrganismes" class="form-select">
            </c-select>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
            <label for="parent">Organisme parent</label>
          </div>
        </ValidationProvider>
      </div>

       <div class="col-sm-12">
        <ValidationProvider rules="" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <c-select id="typeOrganisme" name="typeOrganisme" option-label="libelle" option-value="id" v-model="editableItem.typeOrganisme" :options="typeOrganismes" class="form-select">
            </c-select>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
            <label for="parent">Type Organisme</label>
          </div>
        </ValidationProvider>
      </div>
      
    </div>
  </template>

  </referentiel-list>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ReferentielList from '../../../components/common/referentiel/ReferentielList.vue'
export default {
  components: {
    ReferentielList
  },
  computed: {
    ...mapGetters({
      organismes: 'organisme/organismes',
      typeOrganismes: 'organisme/typeOrganismes'
    }),
    nullableOrganismes () {
      return [ {}, ...this.organismes]
    },
    referentielName () {
      return 'organisme'
    },
    emptyReferentielItem () {
      return {
        intitule: null,
        description: null,
        parent: null
      }
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'intitule', label: 'Nom' },
        { key: 'description', label: 'Description' },
        'actions',
      ]
    },
  },
  created () {
    this.fetchTypeOrganismes()
  },
  methods: {
    ...mapActions({
      fetchOrganismes: 'organisme/fetchOrganismes',
      fetchTypeOrganismes: 'organisme/fetchTypeOrganismes'
    })
  }

}
</script>

<style>

</style>